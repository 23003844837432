import React from 'react'
import PropTypes from 'prop-types'
import LazyLoad from 'react-lazyload'
import CreditCardDebtSimple from './CreditCardDebtSimple'
import DebtReliefPartners from './DebtReliefPartners'
import MoreQuestions from './MoreQuestions'
import ProgramWorks from './ProgramWorks'
import AsFeaturedIn from '../../shared/BrandPages/AsFeaturedIn'
import Faqs from 'src/components/pages/Home/Faqs'
import DebtReliefCalculator from './DebtReliefCalculator'
import DataVisualization from './DataVisualization'

import { useFeatureFlags } from 'src/common/ab-testing/context'
import { featureToggles } from 'src/common/constants/featureToggles'
import { experimentSectionByVariation } from 'src/common/analytics/consts'
import { getPhoneNumber, numberFormat } from 'src/common/utils'
import HeroBannerClientPoints from './HeroBannerClientPoints'
import StaticTrustMarkersWrapper from 'src/components/shared/StaticTrustMarkersWrapper'

const HomePage = ({ data, isDefaultLang }) => {
  const { getExperimentVariation } = useFeatureFlags()
  const homepageDropDownFloatingFooter = getExperimentVariation(
    experimentSectionByVariation[
      featureToggles.HOMEPAGE_DROP_DOWN_FLOATING_FOOTER
    ]
  )

  const {
    heroBanner,
    debtSimple,
    programWorks,
    partners,
    featuredInData,
    trustMarkersCombination,
    trustPilotInfo,
    debtReliefCalculator,
    cfosData,
    isCfosDisclousuresVariationActive = false,
    useTestimonialFormat = false,
  } = data

  const trustPilot = trustPilotInfo?.trustMarker?.find(
    ({ fields }) =>
      fields.id.toLowerCase() === 'truspilot' ||
      fields.id.toLowerCase() === 'trustpilot'
  )

  const newHeroContent = {
    ...heroBanner.content,
    number: getPhoneNumber(true, homepageDropDownFloatingFooter),
    trustPilot: {
      rating: Number(trustPilot?.fields?.rating),
      totalReviews: numberFormat(Number(trustPilot?.fields?.totalReview)),
      link: trustPilot?.fields?.link,
      color: '#33b083',
    },
    newTrustpilotItem: true,
  }

  return (
    <div className="w-content mx-auto">
      <HeroBannerClientPoints
        useTestimonialFormat={useTestimonialFormat}
        content={newHeroContent}
        isDefaultLang={isDefaultLang}
      />
      <StaticTrustMarkersWrapper
        trustMarkersCombination={trustMarkersCombination}
        title="Freedom debt relief is trusted by 1 million clients and counting"
      />
      {/* <NewTrustMarkersWrapper
        trustMarkersCombination={trustMarkersCombination}
        title="America’s top debt relief company"
        isDynamic
      /> */}
      {isDefaultLang ? (
        <LazyLoad offset={145}>
          {isCfosDisclousuresVariationActive ? (
            <DebtReliefCalculator
              data={{
                ...debtReliefCalculator,
                buttonText: 'Get a free evaluation',
                showWarningSection: !isCfosDisclousuresVariationActive,
                title: 'You could free up ${amount} on your monthly payment',
              }}
              initialAmount={25000}
              cfosData={cfosData}
              isCfosDisclousuresVariationActive
            />
          ) : (
            <DataVisualization />
          )}
        </LazyLoad>
      ) : null}
      <CreditCardDebtSimple
        title={debtSimple.title}
        debtTitle={debtSimple.debtTitle}
        debtText={debtSimple.debtText}
        saveTitle={debtSimple.saveTitle}
        saveText={debtSimple.saveText}
        enrollTitle={debtSimple.enrollTitle}
        enrollText={debtSimple.enrollText}
        footer={debtSimple.footer}
        button={debtSimple.button}
      />
      <ProgramWorks
        title={programWorks.title}
        content={programWorks.content}
        steps={programWorks.steps}
        footer={programWorks.footer}
      />
      <div className="bg-gray-130 pb-8 pt-12">
        <AsFeaturedIn items={featuredInData} />
      </div>
      {isDefaultLang ? (
        <>
          <DebtReliefPartners title={partners.title} text={partners.text} />
          <div className="flex items-center justify-center bg-[#EFF5FF] lg:bg-[#EFF5FF]">
            <Faqs />
          </div>
          <MoreQuestions phoneNumber={newHeroContent.number} />
        </>
      ) : null}
    </div>
  )
}

HomePage.defaultProps = {
  isDefaultLang: true,
}

HomePage.propTypes = {
  data: PropTypes.object.isRequired,
  isDefaultLang: PropTypes.bool,
}

export default HomePage
